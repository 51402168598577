<template>
  <div class="aboutMeView">
    <pageHead title="@我的" />
    <van-tabs v-model="active" @click="handleTabChange">
      <van-tab v-for="(item, index) in tabs" :key="index" :title="item.title"/>
    </van-tabs>
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="aboutMeView_content">
      <topicCommunicate
        v-for="item in records"
        :key="item.id"
        :data="item"
        type="about"
        :isShowReply="false"
        @reply="handleReplyComment"
        class="likeView_content_item"
        hideOverview
      />
      <no-more v-if="!params.hasNextPage" style="margin-top: 14px" />
    </div>
    <commentToolDialog
      v-model="editComment"
      :show-dialog.sync="showCommentToolDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      hideRelation
      hide-img
      @publish="handlePublishTopicComment"
    />
  </div>
</template>

<script>
import pageHead from "@/components/pageHead";

import commentToolDialog from '@/components/commentToolDialog'
import topicCommunicate from "@/components/topicItem/communicate";
import {getAttMessage, replyCommentinfo} from "@/api/message";
import NoMore from "@/components/uitls/NoMore";
import {getItem, setItem} from "@/utils/storage";

export default {
  components: {
    pageHead,
    topicCommunicate,
    commentToolDialog,
    NoMore,
  },
  data() {
    return {
      editComment: '',
      // 展示评论弹窗
      showCommentToolDialog: false,
      isAnonymousComment:false,
      commentParams: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      records: [],
      tabs: [
        { title: "未回复", type: 'noRead' },
        { title: "已回复", type: 'isRead' },
      ],
      active: 0
    };
  },
  created () {
    const active = getItem('aboutMeActive') || 0
    this.active = active
  },
  methods: {
    /**
     * 回复评论
     */
    handleReplyComment(data) {
      this.commentParams = {
        messageId: data.module.articleId
      }
      this.showCommentToolDialog = true
    },
    /**
     * 提交评论
     */
    async handlePublishTopicComment() {
      const { editComment: content, commentParams,isAnonymousComment:isAnonymous } = this
      if (!content.trim()) {
        this.$notify({ message: '评论内容不能为空', type: 'danger' })
        return
      }
      await replyCommentinfo({ content, isAnonymous,...commentParams })
      this.editComment = ''
      this.showCommentToolDialog = false
      this.$notify({ message: '回复成功', type: 'success' })
    },
    loadMore() {
      this.loadList();
    },
    loadList() {
      let listQueryParams = this.params;
      if (listQueryParams.hasNextPage) {
        this.params.isReply = !!this.active
        getAttMessage(this.params).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let records = this.records;
          res.records.forEach((item) => {
            let count = records.filter((innerItem) => innerItem.messageId === item.messageId);
            if (count.length === 0) {
              records.push(item);
            }
          });
          this.records = records;
        });
      }
    },
    handleTabChange(name) {
      this.records = []
      const {type} = this.tabs[name]
      switch (type) {
        case 'noRead':
          this.params = {
            type: 4,
            pageNum: 1,
            pageSize: 10,
            hasNextPage: true,
            isReply: 0
          }
          this.loadList();
          break;
        case 'isRead':
          this.params = {
            type: 4,
            pageNum: 1,
            pageSize: 10,
            hasNextPage: true,
            isReply: 1
          }
          this.loadList();
          break;
        default:
          break;
      }
    },
    handleReply(value){
      console.log(value)
    }
  },
  beforeDestroy () {
    setItem('aboutMeActive', this.active)
  }
};
</script>

<style lang="scss" scoped>
::v-deep .topicCommunicateView_content_text{
  padding: 0;
  margin: 10px 0 0 0 ;
}
.aboutMeView {
  height: 100%;
  display: flex;
  flex-direction: column;

  // ::v-deep .van-tab {
  //   color: white;
  // }
  ::v-deep .van-tab--active {
    color: white;
    background-color: $mainColor;
  }
  ::v-deep .van-tabs__line {
    display: none;
  }

  &_content {
    flex: 1;
    overflow-y: auto;

    &_item {
      margin-top: 10px;
    }
  }
}
</style>
